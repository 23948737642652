[
  {
    "title": "Timeless (Stealth Startup)",
    "logo": "timeless_logo",
    "logoHeight": 50,
    "dates": "2024 - Present",
    "copy": [
      "I built and maintained a complete end to end system to automate trading signals that earned our team over $150k in revenue in two months.  This involved a few different Python microservices and a FastAPI admin page, all deployed and maintained in Google Cloud, with >99% uptime and availability.",
      "Building on this success, I played a key role in architecting and implementing an automated trading system that leveraged Python and Google Cloud Pub/Sub technologies. Additionally, I designed a sophisticated trading signal simulator that empowers users to define trade pair patterns and symbols using a straightforward JSON format. This simulator generates continuous, realistic signals for testing against real-world market conditions, further enhancing our trading capabilities and decision-making processes."
    ]
  },
  {
    "title": "Bitcoin Multisig Vault PoC",
    "logoHeight": 50,
    "dates": "2024",
    "copy": [
      "This is a proof of concept I built to illustrate how basic Bitcoin multisignature vaults can be constructed, allowing users to use m-of-n signature schemes for redundancy and security in custodying bitcoin.  It's a single page app that allows a user to step through the flows of using an emulated hardware wallet to create addresses, combine them to a multisig address, recieve funds, and finally provide the signatures to spend from the multisig address.",
      "The app is built with React and uses some JS bitcoin librararies to handle the cryptographic operations on the emulated wallet.  I also used the buidl library to help handle the Bitcoin network interactions as well as protocol level details on the backend."
    ],
    "imgContent": [
      "bitcoin_multisig"
    ]
  },
  {
    "title": "Advanced Spirits",
    "logo": "as_logo",
    "logoHeight": 50,
    "dates": "2023",
    "copy": [
      "I helped the Advanced Spirits team build out an in-house tool to manage, query, and import/export their current whiskey barrel inventory of over 40,000 barrels. The goal was to move from their existing Excel-only workflow to a modern web application with a database. ",
      "I built out most of the features in both React and Django, including import/export Excel and CSV with input data validation, as well as a query translation layer that turned frontend table filters into backend query filters to allow fast, interactive data exploration.  I also leveraged Github Copilot to dramatically speed up my workflow and act more efficiently as the sole full-time engineer on the project."
    ],
    "imgContent": [
      "advanced_spirits_table"
    ]
  },
  {
    "title": "Pearl Street Technologies",
    "logo": "pst_logo",
    "logoHeight": 50,
    "dates": "2022-2023",
    "copy": [
      "At Pearl Street, I helped to grow a web platform that was only a few months old at the time I joined.  This product was crucial for allowing customers to interact with queue data and design simulations to be run on the core backend product, with the goal of saving customers time and money on their interconnection studies.  The main features of the user-facing app I worked on involved Mapbox, MUI X DataGrid tables, and a custom React Hook Form implementation for creating the user-defined simulation scenarios. Also I mentored and brought other engineers up to speed on the tools and technologies (including Typescript, Cypress, and Terraform) in order to help them be more productive.",
      "On the backend, I worked on many of the interfaces and serialization of the data models in Django, using the built-ins of Django Rest Framework and some pandas as well, one highlight being an Azure serverless function that passed summary results between different Django backend services.  In addition, I improved our app's security by implementing a TOTP-based Two Factor Authentication flow using a trusted backend library to handle the details of the cryptography, which was a critical requirement for a product having access to CEII data."
    ],
    "imgContent": [
      "pst_queueviewer_thumb",
      "pst_compare1_thumb",
      "pst_compare2_thumb"
    ]
  },
  {
    "title": "Splash Financial",
    "logo": "splash_logo",
    "logoHeight": 40,
    "dates": "2021-2022",
    "copy": [
      "I helped the Splash team build and maintain their Student Loan and Personal Loan refinancing products.  Highlights are the components I built to collect information on the Prequalification forms, rewriting parts of the Document Upload features, and fixing outstanding tech debt in the existing loan distribution process.  The company had a big emphasis on testing and I made sure every feature I added had at least one unit and integration test to ensure the reliability of their product which handled PII and financial information.  I also saved development teams several minutes in testing and deployment loops by reworking Cypress integration test methodology and container runtimes."
    ]
  },
  {
    "title": "Restream Solutions",
    "logo": "restream_logo",
    "logoHeight": 50,
    "dates": "2019-2021",
    "copy": [
      "I joined this energy industry company to help build out their data collection platform on their own custom built measurement systems in the field, with the end goal to help their customers save on costs and optimize their process times.  I got to work on a wide variety of projects and wear a lot of different hats, but one of the highlights was the revamped web platform I built out during my last year with the company (seen above).   The main technologies I used for it were Typescript/React, React Query for fast data updates and cache invalidation, and Highcharts with some custom state management for the dynamic visualizations.  This took about a year to build all of the features, and I wrote almost all of the code, bringing in some contractors at the end to help parallelize the work, which I also acted as the tech lead for.  This is probably one of the bigger highlights of my career, and it's a product I'm proud to have worked on.  It's still in use at this time of writing by the engineers and customers of the company.",
      "I worked on a number of backend tasks, and this is where I first started learning Django, fixing bugs and adding some features to support the frontend capabilities.  I also worked on the field devices, which also ran Python on some single-board computers.  Mainly the cloud configuration interface and the handling of data for the local on-site UI (run using a local Flask server).  On a couple of occasions, I even got to don a hard hat and head out to the field to help install a 900MHz communication system I devised with the team’s systems engineer."
    ],
    "imgContent": [
      "restream_xui_1_thumb",
      "restream_xui_2_thumb",
      "restream_xui_3_thumb",
      "restream_xui_4_thumb",
      "restream_trailer_thumb",
      "restream_rf_thumb"
    ]
  },
  {
    "title": "Dicelists Password Generator",
    "logo": "",
    "dates": "2018",
    "copy": [
      "I built this tool out of a need to solve one of my own security problems.  I had heard of the Diceware password system and done some research into it, and I wanted a tool to generate memorable passwords (for things like logins, master passwords, and seed phrases), since password generators can handle randomly generated, cryptographically secure passwords for all other credential needs.  (I have researched this field extensively and gave a talk on password security best practices and how entropy is calculated back when I was at NI).  For dicelists, I used a list of words chosen at random from 16 different languages with thousands of words each (more entropy than a single list), using a random seed from Random.org's atmospheric noise API.  "
    ],
    "imgContent": [
      "dicelists_terminal"
    ]
  },
  {
    "title": "Clockwork Solutions",
    "logo": "clockwork_logo",
    "logoHeight": 30,
    "dates": "2018-2019",
    "copy": [
      "In my brief time at Clockwork I got my first feel for working on a “real”, production web software product with paying customers.  Though the product was mostly done and the company appeared to be winding down at the time, I was still able to learn a lot by maintaining some legacy Angular and Flask framework code.  I did build a prototype for a “sophisticated spreadsheet in the cloud” - type analytics product before I left for the next venture.  I was also able to shadow the company's talented Site Reliability Engineers and gain a greater understanding of how Docker and Kubernetes are used both for development and larger scale deployments."
    ]
  },
  {
    "title": "Hack Reactor Final Project - “Hardy Bot” ",
    "logo": "hack_reactor_logo",
    "logoHeight": 40,
    "dates": "2018",
    "copy": [
      "This was the capstone project I put together at the end of my 3-month sprint through the Hack Reactor software development immersive.  I got to lead our team of 3 folks to quickly throw together a working prototype in just a month.  This was a fun project as I got to combine old skills and new; I developed the UI and cloud interface software on the Particle single board computer in C++, and then also put together much of the simple web client in React/Node/MongoDB.",
      "The main idea here was to mimic how an IoT device might talk to the cloud - we pulled various data from a few miscellaneous sources (weather, news, and stock ticker streams) and streamed it to the device which displayed real-time updates on the device to the user.  It gave me an appreciation for building modern interfaces using HTML/CSS - drawing to the device display was much lower level and took far longer!  In the end we succeeded in having the device pull data from various web APIs using Server Sent Events and then update the display in real time, cycling through the various “apps”."
    ],
    "imgContent": [
      "hr_final_dashboard",
      "hr_final_widget_1",
      "hr_final_widget_2",
      "hr_final_widget_3"
    ]
  },
  {
    "title": "National Instruments",
    "logo": "ni_logo",
    "logoHeight": 40,
    "dates": "2013-2018",
    "copy": [
      "As an Applications Engineering Specialist with NI's RF/Wireless team, I designed custom test automation tooling, and provided debugging and software development assistance for customers doing RF SoC silicon validation and verification.  There are a lot of small projects I worked on at NI in a variety of different engineering fields (think, technical sales demos) that I started my career with.  They're too numerous to list here, so I figured I would focus on the bigger opportunities I worked on during my last couple of years there.",
      "I helped a multi-million dollar sales opportunity of new RF test instruments for Intel's 802.11ax chipsets, and also went onsite with Qualcomm and a couple of other chipset manufacturers in the Bay Area.  I worked with (and built!) a lot of LabVIEW and C# tools to assist customers with our driver API and measurement toolkits (WLAN, BT, LTE).  I also had a unique opportunity early on to work on a software defined radio proof of concept for the National Center for Atmospheric Research's dropsonde radios."
    ],
    "imgContent": [
      "ni_one_thumb",
      "ni_two_thumb",
      "ni_three_thumb"
    ]
  },
  {
    "title": "Texas A&M Undergraduate Research",
    "logo": "tamu_logo",
    "logoHeight": 40,
    "dates": "2012",
    "copy": [
      "I got to work on a cool undergrad research project for the Texas A&M Nuclear Engineering Department that I think is worth mentioning here.  I suppose it was my first “real” non-student software assignment that I got paid for (unless you count Excel formulas from the bank I interned at…) ",
      "I built a random number generator in CUDA C to evaluate effectiveness of GPU based random number generation for radiation transport simulations (Monte Carlo methods), and it was a variant of the Mersenne Twister that actually finished raw benchmarks 35.2ms faster than leading alternative, with nearly identical performance in randomness tests.  A victory in my book!"
    ]
  }
]
