{
  "name": "Matt Lee",
  "title": "Full Stack Software Engineer",
  "message_link": "mailto:matt.lee.personal@gmail.com",
  "connect_link": "https://www.linkedin.com/in/matt-lee-b7607b53",
  "github_link": "https://github.com/mattacus",
  "bio": [
    "I'm an engineer with over 10 years of experience building software products in a variety of industries including test and measurement, energy, and finance.  My experience encompasses the entire spectrum of software application development, from front-end design and implementation to back-end architecture and cloud services.",
    "I have worked to build several early stage software products from the ground up, and I'm confident I can get your project off the ground as well!  For your consideration, I've included some of these project highlights below."
  ],
  "skills": [
    "Typescript",
    "Python",
    "React",
    "Django",
    "SQLAlchemy",
    "Highcharts",
    "Material UI",
    "Cypress & Jest",
    "Node & Express",
    "Flask",
    "FastAPI",
    "PostgreSQL",
    "MySQL",
    "Pandas",
    "C++/C#",
    "Github Actions & Jenkins",
    "Github Copilot",
    "Docker",
    "AWS Cloud",
    "Azure Cloud",
    "Google Cloud",
    "Bitcoin Protocol"
  ]
}
